<template>
  <router-view />
</template>

<style lang="less">
html,
body {
    padding: 0;
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.mapboxgl-ctrl-icon {
    padding: 0 !important;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none !important;
}
.mapboxgl-ctrl-logo {
    display: none !important;
}
</style>
